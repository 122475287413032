<template>
  <c-box
    width="100%"
  >
    <c-flex 
      width="100%"
      justify-content="space-between"
      align-items="center"
      gap="8px"
      padding-inline="8px"
    >
      <c-form-control
        pos="relative"
        flex-grow="1"
      >
        <c-image
          :src="require('@/assets/ic-search.svg')"
          alt="icon"
          position="absolute"
          top="50%"
          transform="translateY(-50%)"
          left="15px"
          height="24px"
          z-index="2"
        />
        <c-input
          id="search"
          v-model="search"
          type="text"
          w="100%"
          height="48px"
          placeholder="Pencarian"
          border-radius="20px"
          padding-left="50px"
          position="relative"
          :font-size="['14px', '16px']"
          z-index="1"
          @keyup="onChangeSearch"
        />
      </c-form-control>

      <GlossaryFilter
        :selected="selectedFilter"
        :filters="filter"
        @update:selected="(value) => onChangeFilter(value)"
      />

      <template
        v-if="isAdmin"
      >
        <c-flex
          v-if="!onSelectItem"
          align-items="center"
          flex-shrink="0"
        >
          <c-button
            @click="onClickChooseItem"
          >
            Pilih
          </c-button>
        </c-flex>
        <c-flex
          v-else
          gap="4px"
          align-items="center"
          flex-shrink="0"
        >
          <c-button
            variant-color="primary"
            variant="solid"
            :is-loading="isLoadingChooseAll"
            @click="onClickChooseAll"
          >
            Pilih Semua
          </c-button>
          <c-button
            variant-color="primary"
            variant="ghost"
            :is-disabled="isLoadingChooseAll"
            @click="onClickCancelItem"
          >
            Batal
          </c-button>
          <c-button
            variant-color="danger"
            variant="ghost"
            :is-disabled="isLoadingChooseAll || selectedItems.length === 0"
            @click="onClickDeleteItem"
          >
            <inline-svg
              :src="require('@/assets/icons/icon-trash.svg')"
              height="21"
              width="21"
              fill="#D32737"
            />
          </c-button>
        </c-flex>
      </template>
    </c-flex>
    <c-box
      as="hr"
      w="100%"
      :margin-block="['12px', '16px']"
      border-top="1px solid #E2E8F0"
    />
    <c-flex
      v-show="selectedFilter && selectedFilter.length > 0"
      ref="filterContainer"
      position="relative"
      width="100%"
      margin-bottom="8px"
      align-items="center"
    >
      <c-button
        v-show="isOverflowFilter"
        :display="['none', 'block']"
        width="30px"
        min-width="30px"
        height="30px"
        text-align="center"
        margin="0"
        padding="0"
        border-radius="100%"
        position="absolute"
        left="-35px"
        :is-disabled="false"
        @click="onScrollContent('filterContent', 'left', 15, 100, 5)"
      >
        <c-box
          :width="['12px', '15px']"
          :height="['12px', '15px']"
        >
          <inline-svg
            :src="require('@/assets/icons/icon-arrow-down.svg')"
            height="100%"
            width="100%"
            fill="#008C81"
            style="transform: rotate(90deg);"
          />
        </c-box>
      </c-button>

      <c-button
        v-show="isOverflowFilter"
        :display="['none', 'block']"
        width="30px"
        min-width="30px"
        height="30px"
        text-align="center"
        margin="0"
        padding="0"
        border-radius="100%"
        position="absolute"
        right="-35px"
        :is-disabled="false"
        @click="onScrollContent('filterContent', 'right', 15, 100, 5)"
      >
        <c-box
          :width="['12px', '15px']"
          :height="['12px', '15px']"
        >
          <inline-svg
            :src="require('@/assets/icons/icon-arrow-down.svg')"
            height="100%"
            width="100%"
            fill="#008C81"
            style="transform: rotate(-90deg);"
          />
        </c-box>
      </c-button>
      <c-flex
        ref="filterContent"
        v-dragscroll
        padding-inline="8px"
        padding-block="4px"
        justify-content="flex-start"
        :gap="['6px', '12px']"
        h="auto"
        overflow-x="hidden"
        scroll-behavior="smooth"
      >
        <c-button
          v-for="(item, i) in selectedFilter"
          :key="i"
          flex-shrink="0"
          variant-color="primary"
          variant="outline"
          border-color="transparent"
          bg="primary.50"
          rounded="20px"
          :h="['34px', '40px']"
          :font-size="['14px', '16px']"
          @click="onRemoveFilterItem(item)"
        >
          {{ item }}
          <c-box
            margin-left="6px"
            :width="['18px', '20px']"
            :height="['18px','20px']"
          >
            <inline-svg
              :src="require('@/assets/icons/icon-circle-close.svg')"
              height="100%"
              width="100%"
              fill="#008C81"
            />
          </c-box>
        </c-button>
      </c-flex>
    </c-flex>
    <c-flex
      position="relative"
      width="100%"
      align-items="center"
      :padding-inline="['34px', '0px']"
    >
      <c-button
        v-show="isOverflowChar"
        :width="['26px', '30px']"
        :min-width="['26px', '30px']"
        :height="['26px', '30px']"
        text-align="center"
        margin="0"
        padding="0"
        border-radius="100%"
        position="absolute"
        :left="['6px', '-35px']"
        :is-disabled="false"
        @click="onScrollContent('charContent', 'left', 15, 100, 5)"
      >
        <c-box
          :width="['12px', '15px']"
          :height="['12px', '15px']"
        >
          <inline-svg
            :src="require('@/assets/icons/icon-arrow-down.svg')"
            height="100%"
            width="100%"
            fill="#008C81"
            style="transform: rotate(90deg);"
          />
        </c-box>
      </c-button>

      <c-button
        v-show="isOverflowChar"
        :width="['26px', '30px']"
        :min-width="['26px', '30px']"
        :height="['26px', '30px']"
        text-align="center"
        margin="0"
        padding="0"
        border-radius="100%"
        position="absolute"
        :right="['6px', '-35px']"
        :is-disabled="false"
        @click="onScrollContent('charContent', 'right', 15, 100, 5)"
      >
        <c-box
          :width="['12px', '15px']"
          :height="['12px', '15px']"
        >
          <inline-svg
            :src="require('@/assets/icons/icon-arrow-down.svg')"
            height="100%"
            width="100%"
            fill="#008C81"
            style="transform: rotate(-90deg);"
          />
        </c-box>
      </c-button>
      <c-flex
        ref="charContent"
        v-dragscroll
        justify-content="flex-start"
        :grid-gap="['4px', '8px']"
        h="auto"
        :padding-inline="['4px', '8px']"
        padding-block="4px"
        overflow-x="hidden"
      >
        <c-button
          type="button"
          :h="['26px', '32px']"
          w="auto"
          flex-shrink="0"
          px="6px"
          m="0"
          :font-size="['14px', '16px']"
          line-height="24px"
          rounded="full"
          text-transform="uppercase"
          :color="!char ? 'primary.400' : 'gray.900'"
          :bg="!char ? 'primary.50' : 'white'"
          border-width="1px"
          border-style="solid"
          :border-color="!char ? 'primary.400' : 'gray.900'"
          @click.stop.prevent="onChangeChar('')"
        >
          Semua
        </c-button>
        <c-button
          v-for="item in chars"
          :key="item"
          type="button"
          :h="['26px', '32px']"
          :w="['26px', '32px']"
          :min-w="['26px', '32px']"
          px="0"
          m="0"
          :font-size="['14px', '16px']"
          line-height="24px"
          rounded="full"
          text-transform="uppercase"
          :color="char === item ? 'primary.400' : 'gray.900'"
          :bg="char === item ? 'primary.50' : 'white'"
          border-width="1px"
          border-style="solid"
          :border-color="char === item ? 'primary.400' : 'gray.900'"
          @click.stop.prevent="onChangeChar(item)"
        >
          {{ item }}
        </c-button>
      </c-flex>
    </c-flex>
    
    <c-box
      v-if="isLoading || items.length > 0"
      :min-height="['calc(100vh - 210px)', 'auto']"
    >
      <c-grid
        w="100%"
        :template-columns="['repeat(3, 1fr)', 'repeat(5, 1fr)']"
        :gap="['8px', '16px']"
        mx="auto"
        my="16px"
      >
        <template
          v-if="isLoading"
        >
          <GlossaryItemSkeleton
            v-for="i in Number(perPage)"
            :key="i"
          />
        </template>
        <template
          v-else
        >
          <GlossaryItem 
            v-for="(item, i) in items"
            :key="i"
            :data="item"
            :on-select="onSelectItem"
            :is-selected="isItemChecked(item?.id)"
            @click="onClickGlossary"
          />
        </template>
      </c-grid>
    </c-box>

    <c-flex
      v-else
      width="100%"
      :min-height="['calc(100vh - 200px)', 'auto']"
      flex-direction="column"
      justify-content="center"
      align-items="center"
      padding-block="30px"
    >
      <c-image
        src="https://ik.imagekit.io/dietela/pwa_webp/profile/undraw_empty_re_opql_2.webp?ik-sdk-version=javascript-1.4.3&updatedAt=1677059485965"
        :h="['170px', '300px']"
        mb="20px"
      />
      <c-text
        :font-size="['14px', '16px']"
        color="superDarkGray.900"
      >
        Saat ini belum ada nilai gizi
      </c-text>
    </c-flex>
  
    <c-flex
      justify="space-between"
      align="center"
      padding="8px 16px"
    >
      <c-flex 
        align="center"
        gap="16px"
      >
        <BaseText
          :display="['none', 'block']"
          size-mobile="14px-2"
          size-desktop="16px"
        >
          Show
        </BaseText>
        <c-select
          min-width="140px"
          border-radius="8px"
          size="md"
          color="primary.400"
          :font-size="['16px', '18px']"
          font-weight="500"
          :value="perPage?.toString()"
          @change="(perPage_) => onChangePerPage(perPage_)"
        >
          <option 
            v-for="option in perPageOptions"
            :key="option"
            :value="option"
          >
            {{ option }} Data
          </option>
        </c-select>
      </c-flex>
      <c-flex
        :display="['none', 'flex']"
        font-size="14px"
        font-weight="400"
        font-family="Roboto, sans-serif, Arial"
        padding="12px 0"
        justify-content="center"
        align-items="center"
      >
        <BasePagination
          :page="page"
          :total-page="totalPage"
          @on-change="onChangePage"
        />
      </c-flex>
      <c-flex
        :display="['flex', 'none']"
        gap="12px"
        align-items="center"
      >
        <c-button
          variant="outline"
          variant-color="primary"
          min-width="40px"
          width="40px"
          height="40px"
          border-radius="40px"
          padding="0px"
          :is-disabled="page <= 1"
          @click.prevent="onPrevPage"
        >
          <c-box
            :width="['15px']"
          >
            <inline-svg
              :src="require('@/assets/icons/icon-arrow-down.svg')"
              height="100%"
              width="100%"
              fill="#008C81"
              style="transform: rotate(90deg);"
            />
          </c-box>
        </c-button>
        <c-text
          font-weight="500"
          font-size="16px"
        >
          {{ page }}
        </c-text>
        <c-button
          variant="outline"
          variant-color="primary"
          min-width="40px"
          width="40px"
          height="40px"
          border-radius="40px"
          padding="0px"
          :is-disabled="page == totalPage || items.length == 0"
          @click.prevent="onNextPage"
        >
          <c-box
            :width="['15px']"
          >
            <inline-svg
              :src="require('@/assets/icons/icon-arrow-down.svg')"
              height="100%"
              width="100%"
              fill="#008C81"
              style="transform: rotate(-90deg);"
            />
          </c-box>
        </c-button>
      </c-flex>
    </c-flex>
    <BaseModal
      :is-open="!!isOpenConfirmDelete"
      :close-on-overlay-click="false"
      :with-button-close="false"
    >
      <template #header>
        <c-box
          display="flex"
          justify-content="center"
          margin="24px 0 0 0"
        >
          <c-image
            height="120px"
            object-fit="cover"
            src="https://ik.imagekit.io/dietela/pwa_webp/popup/delete.png?updatedAt=1690890254641"
            alt="image confirmation delete coupon"
          />
        </c-box>
      </template>
      <template #body>
        <c-box
          justify-content="center"
          padding="30px 24px 20px 24px"
          text-align="center"
        >
          <BaseText
            size-mobile="16px"
            size-desktop="18px"
            color="#333333"
          >
            Apakah kamu yakin ingin menghapus item terpilih?
          </BaseText>
        </c-box>
      </template>
      <template #footer>
        <c-flex
          gap="20px"
          width="100%"
          padding-left="24px"
          padding-right="24px"
          margin-bottom="24px"
        >
          <BaseButton
            :left-svg-icon="require('@/assets/icons/icon-circle-close.svg')"
            left-svg-icon-color="#008C81"
            color="primary"
            rounded="1000px"
            width="100%"
            variant="outlined"
            :is-disabled="isLoadingDelete"
            @click="isOpenConfirmDelete = false"
          >
            Batal
          </BaseButton>
          <BaseButton
            :right-svg-icon="require('@/assets/icons/icon-circle-check.svg')"
            right-svg-icon-color="white"
            color="danger"
            rounded="1000px"
            width="100%"
            :is-loading="isLoadingDelete"
            @click="onSubmitDeleteItem"
          >
            Hapus
          </BaseButton>
        </c-flex>
      </template>
    </BaseModal>
  </c-box>
</template>

<script>
import _ from 'lodash'
import { dragscroll } from 'vue-dragscroll'
import BasePagination from '@/components/elements/base-pagination.vue'
import BaseText from '@/components/elements/base-text.vue'
import BaseModal from '@/components/elements/base-modal.vue'
import BaseButton from '@/components/elements/base-button.vue'
import GlossaryItem from '@/components/glossary/glossary-item.vue'
import GlossaryFilter from '@/components/glossary/glossary-filter.vue'
import GlossaryItemSkeleton from '@/components/glossary/glossary-item-skeleton.vue'
import { reqMealGlossaries } from '@/requests/dietela-api/admin/meal-glossaries'
import { reqMealGlossaries as reqMealGlossariesClient } from '@/requests/dietela-api/meal-glossaries'
import { mapState } from 'vuex'

export default {
  name: 'GlossaryContainer',
  components: {
    BasePagination,
    BaseText,
    BaseModal,
    BaseButton,
    GlossaryItem,
    GlossaryFilter,
    GlossaryItemSkeleton,
  },
  directives: {
    dragscroll,
  },
  props: {
    breakpoint: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      search: '',
      debounceSearch: _.debounce(this.onSearch, 1000),
      chars: Array.from({ length: 122 - 97 + 1 }, (_, idx) => String.fromCharCode(97 + idx)),
      char: '',
      page: 1,
      perPage: ['sm', 'md'].includes(this.breakpoint) ? '24' : '25',
      total: 0,
      items: [],
      filter: [],
      isLoading: false,
      onSelectItem: false,
      selectedItems: [],
      isLoadingChooseAll: false,
      isOpenConfirmDelete: false,
      isLoadingDelete: false,
      isOverflowFilter: false,
      resizeObserverFilter: null,
      isOverflowChar: false,
      resizeObserverChar: null,
    }
  },
  computed: {
    ...mapState({
      user: (s) => s.auth.user,
    }),
    isAdmin() {
      return this.user.role === 'admin'
    },
    totalPage() {
      return Math.ceil(this.total / Number(this.perPage))
    },
    selectedFilter() {
      return this.$route.query?.filter ? JSON.parse(this.$route.query?.filter) : []
    },
    perPageOptions() {
      if (['sm', 'md'].includes(this.breakpoint)) {
        return ['24', '48']
      } else {
        return ['25', '50']
      }
    },
  },
  watch: {
    $route: {
      immediate: false,
      handler(val) {
        if (val) {
          this.search = val.query.q ?? ''
          this.page = val.query?.page ? Number(val.query?.page) : 1
          this.perPage = val.query.perpage ? val.query.perpage : (['sm', 'md'].includes(this.breakpoint) ? '24' : '25')
          this.char = val.query.char ?? ''
          this.init()
        }
      },
    },
    breakpoint: {
      handler(val) {
        if (val) {
          this.search = this.$route.query.q ?? ''
          this.page = this.$route.query?.page ? Number(this.$route.query?.page) : 1
          this.perPage = this.$route.query.perpage ? this.$route.query.perpage : (['sm', 'md'].includes(val) ? '24' : '25')
          this.char = this.$route.query.char ?? ''
          this.init()
        }
      },
    },
  },
  mounted() {
    this.resizeObserverFilter = new ResizeObserver(this.onResizeFilter)
    this.resizeObserverFilter.observe(this.$refs['filterContent'].$el)
    this.onResizeFilter()
    this.resizeObserverChar = new ResizeObserver(this.onResizeChar)
    this.resizeObserverChar.observe(this.$refs['charContent'].$el)
    this.onResizeChar()
  },
  beforeUnmount() {
    this.resizeObserverFilter.unobserve(this.$refs['filterContent'].$el)
    this.resizeObserverChar.unobserve(this.$refs['charContent'].$el)
  },
  methods: {
    onResizeFilter() {
      const scrollWidth = this.$refs['filterContent'].$el.scrollWidth
      const clientWidth = this.$refs['filterContent'].$el.clientWidth
      if (scrollWidth > clientWidth) {
        this.isOverflowFilter = true
      } else {
        this.isOverflowFilter = false
      }
    },
    onResizeChar() {
      const scrollWidth = this.$refs['charContent'].$el.scrollWidth
      const clientWidth = this.$refs['charContent'].$el.clientWidth
      if (scrollWidth > clientWidth) {
        this.isOverflowChar = true
      } else {
        this.isOverflowChar = false
      }
    },
    async init() {
      try {
        this.isLoading = true
        let res
        if (this.isAdmin) {
          res = await reqMealGlossaries.get(this.$store.getters.axios, {
            page: this.page,
            perpage: this.perPage,
            q: this.$route.query.q,
            char: this.char,
            filter: this.$route.query.filter,
          })
        } else {
          res = await reqMealGlossariesClient.get(this.$store.getters.axios, {
            page: this.page,
            perpage: this.perPage,
            q: this.$route.query.q,
            char: this.char,
            filter: this.$route.query.filter,
          })
        }
        
        this.items = res.data.data?.result
        this.filter = res.data.data?.filter
        this.total = res.data.meta?.total
      } catch (err) {
        this.$toast({
          title: 'Failed',
          description: err?.response?.data?.message || 'Something went wrong!',
          status: 'error',
          duration: 5000,
          position: 'bottom-right',
        })
      } finally {
        this.isLoading = false
      }
    },
    onChangeSearch() {
      this.debounceSearch()
    },
    onSearch() {
      this.$posthog.capture('filter nilai gizi', {
        filter: this.$route.query?.filter,
        q: this.search,
        char: this.$route.query?.char,
      })
      if (this.search === '' || this.search.length >= 3) {
        this.$router.replace({
          name: this.$route.name,
          query: {
            ...this.$route.query,
            q: this.search,
            page: 1,
          },
          params: { savePosition: true },
        })
      }
    },
    onChangeChar(char) {
      this.$posthog.capture('filter nilai gizi', {
        filter: this.$route.query?.filter,
        q: this.$route.query?.search,
        char: char,
      })
      this.$router.replace({
        name: this.$route.name,
        query: {
          ...this.$route.query,
          char: char,
          page: 1,
        },
        params: { savePosition: true },
      })
    },
    onChangePerPage(perPage) {
      this.$router.replace({
        name: this.$route.name,
        query: {
          ...this.$route.query,
          perpage: perPage,
          page: 1,
        },
        params: { savePosition: true },
      }).catch(() => {})
    },
    onChangePage(page) {
      this.$router.replace({
        name: this.$route.name,
        query: {
          ...this.$route.query,
          page: page,
        },
        params: { savePosition: true },
      })
    },
    onPrevPage() {
      if (this.page > 1) {
        this.onChangePage(this.page - 1)
      }
    },
    onNextPage() {
      if (this.page < this.totalPage) {
        this.onChangePage(this.page + 1)
      }
    },
    onChangeFilter(filter) {
      this.$posthog.capture('filter nilai gizi', {
        filter: filter,
        q: this.$route.query?.search,
        char: this.$route.query?.char,
      })
      this.$router.replace({
        name: this.$route.name,
        query: {
          ...this.$route.query,
          filter: JSON.stringify(filter),
          page: 1,
        },
        params: { savePosition: true },
      })
    },
    onRemoveFilterItem(value) {
      const filter = this.selectedFilter.filter((it) => it !== value)
      this.onChangeFilter(filter)
    },
    onClickChooseItem() {
      this.onSelectItem = true
    },
    async onClickChooseAll() {
      try {
        this.isLoadingChooseAll = true
        let res
        if (this.isAdmin) {
          res = await reqMealGlossaries.getIds(this.$store.getters.axios, {
            q: this.$route.query.q,
            char: this.char,
            filter: this.$route.query.filter,
          })
        } else {
          res = await reqMealGlossaries.getIds(this.$store.getters.axios, {
            q: this.$route.query.q,
            char: this.char,
            filter: this.$route.query.filter,
          })
        }
        this.selectedItems = res.data.data
      } catch (err) {
        this.$toast({
          title: 'Failed',
          description: err?.response?.data?.message || 'Something went wrong!',
          status: 'error',
          duration: 5000,
          position: 'bottom-right',
        })
      } finally {
        this.isLoadingChooseAll = false
      }
    },
    onClickCancelItem() {
      this.onSelectItem = false
      this.selectedItems = []
    },
    onClickDeleteItem() {
      this.isOpenConfirmDelete = true
    },
    async onSubmitDeleteItem() {
      try {
        this.isLoadingDelete = true
        await reqMealGlossaries.multiDelete(this.$store.getters.axios, {
          id: this.selectedItems,
        })
        this.$toast({
          title: 'Success',
          description: 'Successfully deleted selected meal glossary',
          status: 'success',
          duration: 5000,
          position: 'bottom-right',
        })

        if (this.page == 1) {
          this.init()
        } else {
          this.onChangePage(1)
        }
      } catch(err) {
        this.$toast({
          title: 'Failed',
          description: err?.response?.data?.message || 'Something went wrong!',
          status: 'error',
          duration: 5000,
          position: 'bottom-right',
        })
      } finally {
        this.isLoadingDelete = false
        this.isOpenConfirmDelete = false
        this.onClickCancelItem()
      }
    },
    onClickGlossary(item) {
      if (!this.onSelectItem) {
        if (this.isAdmin) {
          return this.$router.push({
            name: 'admin.glossary-detail',
            params: {
              glossaryId: item?.id,
            },
          })
        } else if (this.isClient || this.isRoleNutritionist) {
          return this.$router.push({
            name: 'client.glossaries-detail',
            params: {
              glossaryId: item?.id,
            },
          })
        }
      }
      this.onChangeSelectedItem(item?.id)
    },
    isItemChecked(itemId) {
      if (this.selectedItems.length > 0 && itemId) {
        const check = this.selectedItems.some((it) => it === itemId)
        if (check) {
          return true
        }
      }
      return false
    },
    onChangeSelectedItem(itemId) {
      if (this.isItemChecked(itemId)) {
        this.selectedItems = this.selectedItems.filter((it) => it != itemId)
      } else {
        this.selectedItems = [...this.selectedItems, itemId]
      }
    },
    onScrollContent(el, direction, speed, distance, step) {
      var scrollAmount = 0
      const element = this.$refs[el]
      var slideTimer = setInterval(function() {
        if (direction == 'left') {
          element.$el.scrollLeft -= step
        } else {
          element.$el.scrollLeft += step
        }
        scrollAmount += step
        if (scrollAmount >= distance) {
          window.clearInterval(slideTimer)
        }
      }, speed)
    },
  },
}
</script>

<style>

</style>